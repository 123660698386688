import React, { useEffect, useState, useContext } from "react";
import logo from '@img/logo.webp';
import person from '@img/person.webp';
import { useNavigate, useLocation } from "react-router-dom";
import { ProcesEncrypt } from '../util/anyFunctions'
import { getUserPhoto } from '../api/index';
import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import ToastModal from "./ToastModal";
import UserContext from "../context/useLoginContext";

import '@styles/header.scss';

const Header = ({hiddeSearch=false}) => {
    const navigate = useNavigate();
    const [userHeader, setUserHeader] = useState(null);
    const { setUser } = useContext(UserContext);
    const [message, setMessage] = useState(null);
    const location = useLocation();
    const currentPath = location.pathname;

    const fetchData = async () => {
        if(currentPath !== "/tyc" && currentPath !== "/pdp"){
            const queryParams = new URLSearchParams(window.location.search);
            const token = queryParams.get('token');
            if(token){
                const tokenEncrypted = ProcesEncrypt(token, 3)
                localStorage.setItem("_flat_data", tokenEncrypted)
            }
            // if(currentPath === "/search" && token){
            //     navigate('/search');
            // }
            const loggedInUser = localStorage.getItem("_flat_data");
            // if(currentPath === "/" && loggedInUser){
            //     navigate('/search');
            // }
            if(loggedInUser && !userHeader){
                const response = await getUserPhoto()
                if(response?.status !== "fail"){
                    setUserHeader(response?.data?.user)
                    setUser(response?.data?.user)
                }else{
                    setMessage("¡Lo sentimos!, hubo un error al intentar obtener la información del usuario.")
                    const toastEl = $('#liveToast')[0];
                    if (toastEl) {
                        let toast = new bootstrap.Toast(toastEl, { delay: 6000 });
                        toast.show();
                        navigate('/');
                    }
                }
            }else{
                setUserHeader(null);
                setUser(null)
                // navigate('/');
            }
        }
    };
    useEffect(() => {
        fetchData()
    }, []);

    const logout = () => {
        localStorage.removeItem('_flat_data');
        setUserHeader(null);
        setUser(null)
        navigate('/');
    }

    return (
        <div className="header">
            <img src={logo} alt="firulife-logo" onClick={() => navigate("/")} />
            {
                !userHeader ?
                    <div className="header-items">
                    {
                        !hiddeSearch &&
                            <div className="login" onClick={() => navigate("/search")}>Buscar</div>
                    }
                        <div className="register" onClick={() => navigate("/login")}>Iniciar Sesión</div>
                    </div>
                    :
                    <div className="user-profile">
                        <div className="login" onClick={() => navigate("/dashboard/new-service")}>Agregar Servicio</div>
                        <div className="dropdown">
                            <button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" aria-label="user-menu">
                            <img
                                src={userHeader.photo}
                                alt="firulife-user"
                                onError={(e) => { e.target.onerror = null; e.target.src = person; }}
                            />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" onClick={() => navigate("/dashboard/profile")} >Mi cuenta</a></li>
                                <li><a className="dropdown-item" onClick={() => navigate("/dashboard/firubox")} >FiruBox</a></li>
                                <li><a className="dropdown-item" onClick={() => navigate("/dashboard/favorites")} >Favoritos</a></li>
                                <li><a className="dropdown-item" onClick={() => navigate("/dashboard/services")} >Mis servicios</a></li>
                                <li><a className="dropdown-item no-border" onClick={() => logout()}>Cerrar sesión</a></li>
                            </ul>
                        </div>
                    </div>
            }
            <ToastModal message={message}/>
        </div>
    )
}

export default Header;
